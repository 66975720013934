import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import useCheckLoggedIn from "../../helper/useCheckLoggedIn";
import AdminNavBar from "../../components/AdminNavBar";
import NewEventEditor from '../../components/editor/NewEventEditor';
import Footer from "../../components/Footer";

const EventsPage = () => {

    const { identifier } = useParams();
    const user = Cookies.get('uuid') || null;
    const [loading, setLoading] = useState(false);

    const checkLoggedIn = useCheckLoggedIn();
    useEffect(() => {
        checkLoggedIn(identifier);
        setLoading(false);
    }, [identifier]);

    if (loading) {
        return (
            <div className='flex justify-center min-h-50-screen'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
    <>
        <div className='admin-page'>
            <AdminNavBar />
            <div className='px-4'>
                <NewEventEditor userId={user} identifier={identifier}/>
            </div>
        </div>
        <Footer />
    </>
    )
}

export default EventsPage