import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BookingCalendar from './BookingCalendar';
import BookingForm from './BookingForm';  
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../helper/apiClient';

const UserEventBlock = ({ event, template, identifier }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [eventDate, setEventDate] = useState('');

    const [inputErrors, setInputErrors] = useState(
        {
            name: false,
            email: false,
        }
    );

    const [isDateSelected, setIsDateSelected] = useState(false);
    const [bookStep, setBookStep] = useState(1);

    const handleStepBack = () => {
        if(bookStep === 1) {
            navigate(`/${identifier}/events`, { replace: true })
        } else if (bookStep > 1 ) {
            setBookStep(current => current - 1)
        }
    }

    const handleStepForward = () => {
        if(bookStep < 2) {
            setBookStep(current => current + 1)
        } else {
            handleSendBooking();
        }
    }

    const scrollToTop = () => {
        if(window !== undefined) {
            window.scrollTo({
            top: 0,
            behavior: 'smooth',
            });
        }
    };

    const isError = async () => {
        let hasError = false;

        if (!name) {
            if(!hasError) {
                toast.info('Meg kell adnod a nevedet.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                name: true,
            }));
            hasError = true;
        }

        if (!email) {
            if(!hasError) {
                toast.info('Meg kell adnod az e-mail címed.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                email: true,
            }));
            hasError = true;
        }

        return hasError;
    };


    const handleSendBooking = async () => {
        setInputErrors({ email: false });
        setLoading(true);
    
        try {
            if (await isError()) {
                scrollToTop();
                return;
            }
    
            const bookingData = { name, email, message, eventDate, eventName: event?.label, identifier };
            await apiClient.post(`/api/book`, bookingData);    
            toast.success(`Sikeresen elküldted a foglalásodat, ${identifier}-től hamarosan kapni fogsz egy visszaigazolást.`);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
    <>
    <div className='my-8 w-5/6 sm:w-4/6'>
        <div className="card card-compact shadow-6xl" style={{background: template?.light_color}}>
            <figure>
                <div
                    className="my-0 mx-auto w-full h-40 bg-cover bg-center"
                    style={{
                    backgroundImage: `url(${apiBaseUrl + event?.image})`,
                    }}
                />
            </figure>

            <div className="card-body">
                <div className='flex text-xl font-bold'>
                    <div className='text-cut'>{event?.label}</div>
                </div>

                {event?.description &&
                <>
                <hr />
                <div className='flex text-base'>
                    {event?.description && <div dangerouslySetInnerHTML={{ __html: event?.description }} />}
                </div>
                </>}

                <hr />
                <div className='flex flex-col'>
                    <div className='text-xs font-bold'>Időtartam:</div>
                {(event?.hour || event?.minute) ?
                    <div className='text-base'>{event?.hour} óra {event?.minute} perc</div>
                :
                    ''
                }
                </div>

                {event.headcount &&
                <>
                <hr />
                <div className='flex flex-col'>
                    <div className='text-xs font-bold'>Maximális létszám:</div>
                    <div className='text-base'>{event?.headcount}</div>
                </div>
                </>}

                {event.price &&
                <>
                <hr />
                <div className='flex flex-col'>
                    <div className='text-xs font-bold'>Ár:</div>
                    <div className='text-base'>{event?.price}</div>
                </div>
                </>}
                
                <hr />
                {event?.event_dates.length > 0 &&
                    <div className={`${bookStep === 1 ? 'block' : 'hidden'}`}>
                        <div className="flex flex-col">
                            <div className='text-xs font-bold mb-2'>Válassz időpontot:</div>
                            <BookingCalendar setEventDate={setEventDate} maxHeadcount={event?.headcount} eventDates={event?.event_dates} template={template} setIsDateSelected={setIsDateSelected} />
                        </div>
                    </div>
                }

                <div className={`${bookStep === 2 ? 'block' : 'hidden'}`}>
                    <div className="flex flex-col">
                        <div className='text-xs font-bold mb-2'>Kérlek töltsd ki az alábbi adatokat:</div>
                        <BookingForm message={message} setMessage={setMessage} name={name} setName={setName} email={email} setEmail={setEmail} template={template} />
                    </div>
                </div>


                    <div className="card-actions justify-between pb-4 px-4 mt-8">
                        <button
                            // disabled={!isDateSelected}
                            style={{
                                "--hover-color": template?.primary_color,
                                "--bg-color": template?.secondary_color,
                            }}
                            className="btn btn-outline bg-[--hover-color] hover:bg-[--bg-color]"
                            onClick={() => handleStepBack()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19 12H6M12 5l-7 7 7 7"/></svg>
                            Vissza
                        </button>
                        <button
                            disabled={!isDateSelected}
                            style={{
                                "--hover-color": template?.primary_color,
                                "--bg-color": template?.secondary_color,
                            }}
                            className="btn btn-outline bg-[--bg-color] hover:bg-[--hover-color]"
                            onClick={() => handleStepForward()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
                            {bookStep === 1 && 'Tovább'}
                            {bookStep === 2 && 'Foglalás'}
                        </button>
                    </div>
            </div>
        </div>
    </div>

    
    </>
    )
}

export default UserEventBlock