import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useCheckLoggedIn = () => {
    const navigate = useNavigate();

    return (identifier) => {
        const user = Cookies.get('uuid');

        if (!user) {
            Cookies.remove('uuid');
            Cookies.remove('firstname');
            navigate(`/${identifier}/login`, { replace: true });
        }
    };
};

export default useCheckLoggedIn;