import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import AdminMenu from './AdminMenu'
import Cookies from 'js-cookie';
import '../App.css';


const NavBar = () => {

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const { identifier } = useParams();

    const [loading, setLoading] = useState(false);
    const user = Cookies.get('uuid') || null;
    const userFirstName = Cookies.get('firstname') || '';

    const handleLogout = () => {
        Cookies.remove('uuid');
        Cookies.remove('firstname');
    };

    useEffect(() => {
        setLoading(true);
        
        if(!user) {
            handleLogout();
            navigate(`/${identifier}/admin`, { replace: true });    
        }
      
        setLoading(false);

    }, []);

  return (
    <div className="navbar bg-t-beige mb-10 shadow-md m-2 w-[calc(100%-1rem)] rounded-lg px-4">
        <div className="navbar-start">
            <div className="text-xl flex items-center">
                <img src={`${baseUrl}timely_logo.svg`} width="40px" height="40px"/>
                Timely
            </div>
        </div>
        <div className="navbar-center hidden min-[420px]:flex">
            <p className="text-base">Szia{userFirstName && `, ${userFirstName}`}!</p>
        </div>
        <div className="navbar-end">
            <AdminMenu identifier={identifier} />
        </div>
    </div>
  )
}

export default NavBar