import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import useCheckLoggedIn from "../../helper/useCheckLoggedIn";
import AdminNavBar from "../../components/AdminNavBar";
import ProfileEditor from "../../components/editor/ProfileEditor";
import EmailEditor from "../../components/editor/EmailEditor";
import PasswordEditor from "../../components/editor/PasswordEditor";
import Footer from "../../components/Footer";

const AccountPage = () => {

  const { identifier } = useParams();
  const user = Cookies.get('uuid') || null;
  const [loading, setLoading] = useState(false);

  const checkLoggedIn = useCheckLoggedIn();
  useEffect(() => {
      checkLoggedIn(identifier);
      setLoading(false);
  }, [identifier]);

  if (loading) {
    return (
        <div className='flex justify-center min-h-50-screen'>
            <span className="loading loading-spinner loading-lg"></span>
        </div>
    );
  }

  return (
  <>
    <div className='admin-page'>
      <AdminNavBar />
      <div className='px-4'>
        <ProfileEditor userId={user} identifier={identifier}/>
        <EmailEditor userId={user} identifier={identifier}/>
        <PasswordEditor userId={user} identifier={identifier}/>
      </div>
    </div>
    <Footer />
  </>
  )
}

export default AccountPage