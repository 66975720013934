import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import apiClient from '../../helper/apiClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillCustomFormats, quillCustomModules } from '../../helper/quillOptions';
import EventDatesBlock from './EventDatesBlock';
import { useNavigate } from 'react-router-dom';

export default function EventEditor({ event, userId, identifier }) {

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const [eventDeleteConfirm, setEventDeleteConfirm] = useState(false);

    const handleDeleteEvent = () => {
      if(imageUrl) {
        handleDeleteImage();
      }

      handleDelete();
    };

    useEffect(() => {
      if (!event || !event?.id) {
          toast.error('Hiba történt, hamarosan átirányítunk az eseményekhez.');
          setTimeout(() => {
            navigate(`/${identifier}/admin/events`, { replace: true })
          }, 2500);
      }
  }, [event]);

    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [eventId, setEventId] = useState(event?.id || null);

    const [eventDates, setEventDates] = useState(event?.event_dates || []);
    const [originalEventDates, setOriginalEventDates] = useState(event?.event_dates || []);

    const addEventDate = () => {
        const newEventDate = { uuid: uuidv4(), date: null, start_hour: null, start_minute: null };
        setEventDates([...eventDates, newEventDate]);
    };

    const removeEventDate = (uuid) => {
        setEventDates((prevEventDates) =>
            prevEventDates.filter((eventDate) => eventDate.uuid !== uuid)
        );
    };

    const updateEventDate = (uuid, newEventDate) => {
        setEventDates(eventDates?.map(eventDate => eventDate.uuid === uuid ? newEventDate : eventDate));
    };
    
    const [online, setOnline] = useState(false);
    const [address, setAddress] = useState('');
    const [active, setActive] = useState(event?.active || false);
    const [label, setLabel] = useState(event?.label || '');
    const [description, setDescription] = useState(event?.description || '');
    const [imageUrl, setImageUrl] = useState(event?.image || '');
    const [price, setPrice] = useState(event?.price || 0);
    const [hour, setHour] = useState(event?.hour || 0);
    const [minute, setMinute] = useState(event?.minute || 0);
    const [headcount, setHeadcount] = useState(event?.headcount || 0);
    // const [dateType, setDateType] = useState(event?.date_type || '');
    const [dateType, setDateType] = useState('onetime');
    
    const [recurringDays, setRecurringDays] = useState(event?.recurring_days ? JSON.parse(event.recurring_days) :{
      H: false,
      K: false,
      Sze: false,
      Cs: false,
      P: false,
      Szo: false,
      V: false,
    });
  
  
    const [startHour, setStartHour] = useState(event?.start_hour || 0);
    const [startMinute, setStartMinute] = useState(event?.start_minute || 0);
  
    const [selectedImage, setSelectedImage] = useState('');
    const [isSavedImage, setIsSavedImage] = useState(event?.image ? true : false);
    const [imageDeleteConfirm, setImageDeleteConfirm] = useState(false);
    

    useEffect(() => {
        if (isSaved) {
            toast.success('Sikeres mentés.')
        }
    }, [isSaved]);

    const handleDelete = async () => {
      try {

          const response = await apiClient.post(`/api/event/delete`, { userId, eventId: event?.id });
          
          if (response.status === 200) {
            toast.success('Sikeresen törölted az eseményt.')
            navigate(`/${identifier}/admin/events`, { replace: true })
          }
          
      } catch (error) {
        console.log('error', error)
        if (error.code === 'ECONNABORTED') {
          toast.error('A szerver nem válaszolt időben.');
        } else if (error.response && error.response.data && error.response.data.code === 'ER_DUP_ENTRY') {
          toast.error('A szolgáltatás már létezik.');
        } else {
          console.error('Error:', error);
          toast.error('Hiba történt.');
        }
      }
  };

  const getEventDatesIds = (eventDates) => eventDates.map((eventDate) => eventDate.uuid);

  const handleSave = async (type) => {
    try {
      
      let isActive = active;
      if (!validateEventDetails()) {
        isActive = false;
      }

      const uniqueEventDates = eventDates.reduce((accumulator, current) => {
        const key = `${current.date}-${current.start_hour}-${current.start_minute}`;
        if (!accumulator.some(event => `${event.date}-${current.start_hour}-${current.start_minute}` === key)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);

      const eventDatesIds = getEventDatesIds(uniqueEventDates);
      const originalEventDatesIds = getEventDatesIds(originalEventDates);
      const deletableEventIds = originalEventDatesIds.filter(uuid => !eventDatesIds.includes(uuid));

      const event = {
        id: eventId,
        label,
        active: isActive,
        online,
        address,
        description,
        price,
        headcount,
        image: imageUrl,
        hour,
        minute,
        date_type: dateType,
        recurring_days: JSON.stringify(recurringDays),
        start_hour: startHour,
        start_minute: startMinute,
        event_dates: uniqueEventDates
      }
      const response = await apiClient.post(`/api/event/save`, { event, userId, deletableEventIds });
      
      if (response.status === 200) {
        if(type === 'save') {
          setEventDates(uniqueEventDates);
          toast.success('Sikeres mentés.')
        } else if(type === 'save_and_close') {
          setEventDates(uniqueEventDates);
          toast.success('Sikeres mentés.')
          handleClose()
        } else if(type === 'image') {
          toast.success('Sikeres kép mentés.')
        } else {
          toast.success('Sikeres mentés.')
        }
      }
        
    } catch (error) {
      console.log('error', error)
      if (error.code === 'ECONNABORTED') {
        toast.error('A szerver nem válaszolt időben.');
      } else if (error.response && error.response.data && error.response.data.code === 'ER_DUP_ENTRY') {
        toast.error('A szolgáltatás már létezik.');
      } else {
        console.error('Error:', error);
        toast.error('Hiba történt.');
      }
    }
  };

    useEffect(() => {
      if(selectedImage) {
        handleImageSave();
      }
    }, [selectedImage]);
    
    // const handleRecurringDaysChange = (event) => {
    // const { name, checked } = event.target;
    // setRecurringDays((prevDays) => ({
    //     ...prevDays,
    //     [name]: checked,
    // }));
    // };

    // const getRecurringDayLabel = (dayCode) => {
    // const days = {
    //     H: "H",
    //     K: "K",
    //     Sze: "Sze",
    //     Cs: "Cs",
    //     P: "P",
    //     Szo: "Szo",
    //     V: "V",
    // };
    // return days[dayCode];
    // };

    
      const handleImageSave = async () => {
      
        if (!selectedImage) {
          return;
        }
      
        if (!selectedImage.type?.startsWith('image/')) {
          setSelectedImage('');
          toast.error('Kizárólag képet tölthetsz fel.');
          return;
        }
      
        if (selectedImage.type !== 'image/png' && selectedImage.type !== 'image/jpeg' && selectedImage.type !== 'image/gif') {
          setSelectedImage('');
          toast.warning('Nem támogatott képformátum.');
          return;
        }
      
        if (!(selectedImage.size <= 1000000)) {
          setSelectedImage('');
          toast.warning('A kép nem lehet nagyobb, mint 1 MB.');
          return;
        }
      
        const fileExtension = selectedImage.name.split('.').pop();
        const newFileName = `${identifier}-${Date.now()}-e.${fileExtension}`;
        const renamedImage = new File([selectedImage], newFileName, { type: selectedImage.type });
      
        const formData = new FormData();
        formData.append('file', renamedImage);
        formData.append('userId', userId);
        formData.append('eventId', eventId);
        formData.append('identifier', identifier);
      
        try {
          const response = await fetch(`${apiBaseUrl}api/event-image/upload`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
            body: formData,
          });
      
          if (response.ok) {
            setSelectedImage('');
            setImageUrl('uploads/events/'+newFileName);
            setIsSavedImage(true);
            handleSave('image');
          } else {
            toast.error('Hiba történt.');
          }
        } catch (error) {
          toast.error('Hiba történt.');
        } finally {
        }
      };
      
    
      const handleDeleteImage = async () => {
        try {
          const response = await fetch(`${apiBaseUrl}api/event-image/delete`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            body: JSON.stringify({ eventId, userId, identifier, imageName: imageUrl.replace('uploads/', '')}),
          });
    
          if (response.ok) {
            setImageDeleteConfirm(false);
            setIsSavedImage(false);
            setImageUrl('');
            setSelectedImage('');
            toast.success('Kép törlése sikeres.');
          } else {
            toast.error('Nem sikerült törölni a képet.');
          }
        } catch (error) {
          toast.error('Hiba történt a törlés során.');
        }
      };
    
      // Helper function to handle input changes
      const handleDigitInput = (e, maxDigits, setter, maxRange) => {
        const value = e.target.value.replace(/\D/, ''); // Remove non-digit characters
        if (value.length <= maxDigits && Number(value) <= maxRange) { // Allow max 2 digits and within range
          // Ensure 0 is correctly interpreted
          setter(value === '' ? '' : Number(value));
        }
      };
    
    
      // const handleBlurStartHour = () => {
      //   if (startHour.length === 1) {
      //     setStartHour(`0${startHour}`);
      //   }
      // };
    
      // const handleBlurStartMinute = () => {
      //   if (startMinute.length === 1) {
      //     setStartMinute(`0${startMinute}`);
      //   }
      // };
    
      // const handleStartHourChange = (e) => {
      //   const value = e.target.value;
      //   if (/^(2[0-3]|1[0-9]|[0-9])$/.test(value) || value === "") {
      //     setStartHour(value);
      //   }
      // };
    
      // const handleStartMinuteChange = (e) => {
      //   const value = e.target.value;
      //   if (/^[1-5]?[0-9]$/.test(value) || value === "") {
      //     setStartMinute(value);
      //   }
      // };

  
      const validateEventDetails = () => {
        if (!imageUrl) {
          toast.info('Kérlek töltsd fel egy képet.');
          return false;
        }
        if (!label) {
          toast.info('Kérlek add meg az esemény nevét.');
          return false;
        }
        if (!price) {
          toast.info('Kérlek add meg az esemény árát.');
          return false;
        }
        if (hour <= 0 && minute <= 0) {
          toast.info('Kérlek add meg az esemény időtartamát.');
          return false;
        }
        if (!headcount) {
          toast.info('Kérlek add meg az esemény létszámát.');
          return false;
        }
        if (!dateType || dateType === "type") {
          toast.info('Kérlek add meg az esemény típusát.');
          return false;
        }
        if (dateType === "onetime" && (eventDates?.length === 0)) {
          toast.info('Kérlek add meg, hogy mikor lesz az esemény.');
          return false;
        }
        if (dateType === "recurring" && !Object.values(recurringDays).some(value => value)) {
          toast.info('Kérlek add meg, hogy mikor lesz az esemény.');
          return false;
        }
        return true;
      };
      
    
      const handleActive = (checked) => {
        if (!validateEventDetails()) {
          setActive(false);
          return;
        }
        setActive(checked);
      };


      const handleClose = () => {
        navigate(`/${identifier}/admin/events`, { replace: true })
      }
    
    
      if(loading) {
        return (
          <div className='flex justify-center min-h-50-screen'>
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        )
      }
    
      return (
        <>
    <div className="flex justify-between items-center text-3xl mb-12 font-medium leading-none tracking-tight text-t-dark">
      <div>Esemény szerkesztése</div>
    </div>
         <div className="w-full sm:w-10/12 max-w-5xl p-0 m-auto">
            <div className="card bg-base-100 shadow-6xl">
              <figure>
                {isSavedImage || selectedImage ? (
                  <div
                    className="my-0 mx-auto w-full h-40 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${isSavedImage ? apiBaseUrl + imageUrl : URL.createObjectURL(selectedImage)})`,
                    }}
                  >
                    <div className='flex justify-end items-start h-40 p-2'>
                      {imageDeleteConfirm ? (
                        <>
                          <button
                            className="btn bg-t-green btn-outline hover:bg-t-green min-h-8 h-8 px-1 mr-2"
                            onClick={() => setImageDeleteConfirm(false)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 17l-5-5 5-5M18 17l-5-5 5-5"/></svg>
                          </button>
                          
                          <button
                            className="btn bg-t-pink btn-outline hover:bg-t-pink min-h-8 h-8 px-1"
                            onClick={handleDeleteImage}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                          </button>
                        </>
                      ) : isSavedImage && (
                        <button
                          className="btn bg-t-pink btn-outline hover:bg-t-pink min-h-8 h-8 px-1"
                          onClick={() => setImageDeleteConfirm(true)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <label
                    htmlFor={`new-event-image-${eventId}`}
                    className="flex flex-col items-center justify-center w-full h-40 border-2 border-t-gray border-dashed rounded-2xl rounded-b-none cursor-pointer bg-t-light hover:bg-t-light"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-t-dark"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-t-dark">
                        <span className="font-semibold">Kattints a feltöltéshez</span> vagy húzd ide a fájlt
                      </p>
                      <p className="text-xs text-t-dark">PNG, JPG vagy GIF (MAX. 1MB)</p>
                    </div>
                    <input
                      id={`new-event-image-${eventId}`}
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) setSelectedImage(file);
                      }}
                    />
                  </label>
                )}
              </figure>
    
            <div className="card-body p-4 xs:px-8 sm:px-12 md:px-20 lg:px-28 xl:px-32">
              <div className='flex justify-end'>
                  <label className="cursor-pointer label justify-start w-fit">
                    <input type="checkbox" 
                      checked={active}
                      onChange={(e) => handleActive(e.target.checked)}
                      // onChange={(e) => setActive(e.target.checked)}
                      className="toggle mr-2" />
                    <span className="label-text text-t-dark text-lg">Bekapcsolás</span>
                  </label>
                </div>
    
                <div className='flex'>
                    <input
                        className='text-xl w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
                        placeholder="Szolgáltatásod neve"
                        value={label}
                        maxLength={24}
                        onChange={(e) => setLabel(e.target.value)}
                    />
                </div>
    
                <hr />
                <label className="cursor-pointer label justify-start w-fit">
                  <input type="checkbox" 
                    className="checkbox border-t-dark bg-t-light mr-2"
                    checked={online}
                    onChange={(e) => setOnline(e.target.checked)}
                  />
                  <span className="label-text text-t-dark text-base">Online esemény</span>
                </label>

                <hr />
                {!online ?
                  <div>Esemény helyszíne</div>
                :
                  <div>Esemény linkje</div>
                }
                <input
                  className='text-xl w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
                  type='text'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />

                <hr />
                <div>Leírás</div>
                <ReactQuill 
                  className='bg-t-light'
                  modules={quillCustomModules}
                  formats={quillCustomFormats}
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
    
                <hr className='mt-2'/>
                <div>Ár</div>
                <input
                  type='text'
                  className='w-full sm:w-2/6 focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
                  placeholder="0"
                  value={price}
                  onChange={(e) => handleDigitInput(e, 10, setPrice, 1000000)}
                />
    
                <hr />
                <div>Időtartam</div>
    
                <div className="join w-full sm:w-3/6 justify-center rounded-none">
                  <select
                      className="select w-1/2 focus:outline-none rounded-none join-item border border-t-ccc bg-t-light"
                      value={hour}
                      onChange={(e) => setHour(Number(e.target.value))}
                  >
                      <option disabled value={10}>Időtartam</option>
                      <option value={0}>0 óra</option>
                      <option value={1}>1 óra</option>
                      <option value={2}>2 óra</option>
                      <option value={3}>3 óra</option>
                      <option value={4}>4 óra</option>
                  </select>
                  <select
                      className="select w-1/2 focus:outline-none rounded-none join-item border border-t-ccc bg-t-light"
                      value={minute}
                      onChange={(e) => setMinute(Number(e.target.value))}
                  >
                      <option disabled value={10}>Időtartam</option>
                      <option value={0}>0 perc</option>
                      <option value={15}>15 perc</option>
                      <option value={30}>30 perc</option>
                      <option value={45}>45 perc</option>
                  </select>
                </div>
    
                <hr />
                <div>Létszám</div>
    
                <input
                  type='text'
                  className='w-full sm:w-1/6 focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
                  placeholder="Létszám"
                  value={headcount}
                  onChange={(e) => handleDigitInput(e, 2, setHeadcount, 100)}
                />
                
              {/* <label className="cursor-pointer label justify-start w-fit mt-8">
                <input type="checkbox" 
                  className="checkbox border-t-dark bg-t-light mr-2"
                  // checked={fixDate} 
                  // onChange={e => setFixDate(e.target.checked)}
                />
                <span className="label-text text-t-dark text-lg">Fix dátum megadása.</span>
              </label> */}
    
                <hr />
                {/* <div>Típus</div>
    
                <div className="w-full justify-center rounded-none">
                  <select
                    className="select w-full focus:outline-none rounded-none border border-t-ccc bg-t-light"
                    value={dateType || "type"}
                    onChange={(e) => setDateType(e.target.value)}
                  >
                    <option value="type">Típus</option>
                    <option value="onetime">Egyedi</option>
                    <option value="recurring">Ismétlődő</option>
                  </select>
                </div>
    
    
              {dateType === "recurring" &&
              <>
                <div className="w-full justify-center rounded-none">
                <div className='my-2'>Milyen napokon lesz?</div>
                  <ul className="grid w-full gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                    {Object.entries(recurringDays).map(([dayCode, checked]) => (
                        <li key={`${eventId}-${dayCode}`}>
                            <input type="checkbox" id={`${eventId}-${dayCode}`} name={dayCode}
                              checked={checked}
                              onChange={handleRecurringDaysChange} className="hidden peer"/>
                            <label htmlFor={`${eventId}-${dayCode}`} className="inline-flex items-center justify-center w-full p-2 text-t-dark bg-t-gray border-2 border-t-ccc rounded-lg cursor-pointer peer-checked:border-t-dark peer-checked:bg-t-blue peer-checked:text-t-dark hover:text-t-light hover:bg-t-dark">                           
                                <div className="block">
                                    {getRecurringDayLabel(dayCode)}
                                </div>
                            </label>
                        </li>
                    ))}
                    </ul>
                </div>
                <div className="flex w-full mb-4 bg-white">
                  <div>
                    <div className='my-2'>Mikor kezdődik?</div>
                      <div className="join rounded-none relative flex items-center w-1/2 border border-t-ccc">
                        <input
                          type="text"
                          value={startHour}
                          onChange={handleStartHourChange}
                          onBlur={handleBlurStartHour}
                          placeholder="00"
                          className="text-right focus:outline-none w-full p-2 bg-t-light"
                        />
                        <span>:</span>
                        <input
                            type="text"
                            value={startMinute}
                            onChange={handleStartMinuteChange}
                            onBlur={handleBlurStartMinute}
                            placeholder="00"
                            className="text-left focus:outline-none w-full p-2 bg-t-light"
                        />
                      </div>
                  </div>
                </div>
              </>
              } */}
                <div>
                    {dateType === 'onetime' && (
                        <>
                        {eventDates?.length !== 0 ? (
                          eventDates.sort((a, b) => new Date(a.date) - new Date(b.date)).map((eventDate) => (
                            <EventDatesBlock
                                key={eventDate.uuid}
                                eventId
                                eventDate={eventDate}
                                updateEventDate={updateEventDate}
                                removeEventDate={removeEventDate}
                            />
                          ))
                        ) : (
                          <p className="text-center text-lg mt-4">Legalább egy dátumot meg kell adnod.</p>
                        )}
    
                    <div className='flex mt-4 w-full items-center justify-end'>
                        <button
                            className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                            onClick={addEventDate}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                            Új dátum hozzáadása
                        </button>
                    </div>
                    </>
                    )}
                </div>
    
    
              {/* {dateType === "free" &&
                <div className="w-full justify-center rounded-none">
    
                </div>
              }           */}
              <hr />
              <div>
                {eventDeleteConfirm ? (
                  <>
                      <button
                      className="btn bg-t-yellow btn-outline mr-2 hover:bg-t-yellow/75"
                      onClick={() => setEventDeleteConfirm(false)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                        Mégse
                      </button>
                      <button
                        className="btn bg-t-pink btn-outline hover:bg-t-pink/75"
                        onClick={handleDeleteEvent}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        Végleges törlés
                      </button>
                  </>
                ) : (
                  <button
                      className="btn bg-t-pink btn-outline hover:bg-t-pink/75"
                      onClick={() => setEventDeleteConfirm(true)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    Esemény törlése
                  </button>
                )}
            </div>
            <hr/>
              <div className="card-actions justify-end h-16">

                <div className='absolute bottom-4 right-4'>
                  <div className='modal-action'>
                    <button
                        className="btn bg-t-yellow btn-outline mt-4 hover:bg-t-yellow/75"
                        onClick={() => handleClose()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                        <span className='hidden sm:inline-flex'>Mégse</span>
                    </button>
                    <button
                        className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                        onClick={() => handleSave('save')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                        <span className='hidden sm:inline-flex'>Mentés</span>
                    </button>
                    <button
                        className="btn bg-t-green btn-outline mt-4 hover:bg-t-green/75"
                        onClick={() => handleSave('save_and_close')}
                    >
                        <svg className='inline-flex' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                        <span className='inline-flex sm:hidden'>&</span>
                        <svg className='inline-flex sm:hidden' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                        <span className='hidden sm:inline-flex'>Mentés és bezárás</span>
                    </button>
                  </div>
                </div>
    
              </div>
            </div>
          </div>
          </div>
    </>
      );

      }