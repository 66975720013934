import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import useCheckLoggedIn from "../../helper/useCheckLoggedIn";
import AdminNavBar from "../../components/AdminNavBar";
// import AdminEventMiniBlocks from '../../components/editor/AdminEventMiniBlocks';
import AdminEventMiniBlock from '../../components/editor/AdminEventMiniBlock';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../../helper/apiClient';
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/Footer";

const EventsPage = () => {

    const { identifier } = useParams();
    const user = Cookies.get('uuid') || null;
    const [loading, setLoading] = useState(false);

    const checkLoggedIn = useCheckLoggedIn();
    useEffect(() => {
        checkLoggedIn(identifier);
        setLoading(false);
    }, [identifier]);

    const navigate = useNavigate();
    const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
        if (isSaved) {
            toast.success('Sikeres mentés.')
            setIsSaved(false);
        }
    }, [isSaved]);

    const [events, setEvents] = useState([]);

    useEffect(() => {

        const getUserEvents = async () => {
        setLoading(true);
    
        const fieldData = {
            userId: user,
            identifier
        };
    
        try {
            const response = await apiClient.post(`/api/events/select`, fieldData);
    
            if (response.status === 404) {
            // handleLogout();
            setLoading(false);
            return;
            }

            const data = response.data;
            if (data) {
            const orderedEvents = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setEvents(orderedEvents);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
        };

        getUserEvents();
    }, []);


    if (loading) {
        return (
            <div className='flex justify-center min-h-50-screen'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
    <>
        <div className='admin-page'>
            <AdminNavBar />
            <div className='px-4'>
                {/* <AdminEventMiniBlocks userId={user} identifier={identifier}/> */}
                <div className="flex justify-between items-center mb-12 text-t-dark">
                    <div className='text-3xl font-medium leading-none tracking-tight'>Eseményeim</div>
                    {/* <button
                        onClick={() => navigate(`/${identifier}/admin/event/new`, { replace: true })}
                        className='sm:hidden inline-flex btn bg-t-green btn-outline hover:bg-t-green/75 font-medium text-2xl'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    </button> */}
                    <button
                        onClick={() => navigate(`/${identifier}/admin/event/new`, { replace: true })}
                        className='inline-flex btn bg-t-green btn-outline hover:bg-t-green/75 font-medium text-2xl'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        <span className="text-base hidden sm:inline-flex">Új hozzáadása</span>
                    </button>
                    </div>
                    <div className='grid sm:grid-cols-2 xl:grid-cols-3 gap-4'>
                    {events && events.map(event => (
                        <AdminEventMiniBlock
                        key={event.id}
                        identifier={identifier}
                        event={event}
                        />
                    ))}
                </div>
            </div>
        </div>
        <Footer />
    </>
    )
}

export default EventsPage