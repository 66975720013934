import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import UserHomePage from './pages/UserHomePage';
// import Admin from './pages/Admin';
import Register from './pages/Register';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from './components/Logo';
import SuccessRegister from './pages/SuccessRegister';
import AdminForgotPassword from './pages/AdminForgotPassword';
import AdminNewPassword from './pages/AdminNewPassword';
import UserEventPage from './pages/UserEventPage';
import UserEventsPage from './pages/UserEventsPage';

import AdminAccountPage from './pages/admin/AccountPage';
import AdminCalendarPage from './pages/admin/CalendarPage';
import AdminIntroductionPage from './pages/admin/IntroductionPage';
import AdminWorkplanPage from './pages/admin/WorkplanPage';
import AdminEventsPage from './pages/admin/EventsPage';
import AdminNewEventPage from './pages/admin/NewEventPage';
import AdminEditEventPage from './pages/admin/EditEventPage';

import AdminLoginPage from './pages/admin/LoginPage';

import '../src/App.css';




function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        closeButton={false}
        transition={Bounce}
      />
      <Logo/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<SuccessRegister />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/:identifier/login" element={<AdminLoginPage />} />
        <Route path="/:identifier/forgotpassword" element={<AdminForgotPassword />} />
        <Route path="/:identifier/newpass/:forgotpasswordid" element={<AdminNewPassword />} />

        <Route path="/:identifier" element={<UserHomePage />} />
        <Route path="/:identifier/events" element={<UserEventsPage />} />
        <Route path="/:identifier/event/:eventId" element={<UserEventPage />} />

        <Route path="/:identifier/admin/account" element={<AdminAccountPage />} />
        <Route path="/:identifier/admin/calendar" element={<AdminCalendarPage />} />
        <Route path="/:identifier/admin/introduction" element={<AdminIntroductionPage />} />
        <Route path="/:identifier/admin/workplan" element={<AdminWorkplanPage />} />
        <Route path="/:identifier/admin/events" element={<AdminEventsPage />} />
        <Route path="/:identifier/admin/event/new" element={<AdminNewEventPage />} />
        <Route path="/:identifier/admin/:eventId/edit" element={<AdminEditEventPage />} />



        
        {/* <Route path="/:identifier/admin" element={<Admin />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
