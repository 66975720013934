import React from 'react'

const BookingForm = ({ name, setName, email, setEmail, message, setMessage }) => {

    const labelClass = "input input-bordered focus:outline-none flex items-center gap-2 w-full p-2 border border-t-ccc bg-t-light"

    return (
        <>
        <label className={`${labelClass}`}>
            Név
            <input type="text" className="grow"
                placeholder="Timely Tim"
                value={name}
                onChange={e => setName(e.target.value)}
            />
        </label>

        <label className={`${labelClass} mt-2`}>
            E-mail
            <input type="email" className="grow"
                placeholder="hello@timely.hu"
                value={email} 
                onChange={e => setEmail(e.target.value)} 
            />
        </label>

        <textarea
            id="message"
            rows="4"
            className="textarea textarea-bordered focus:border-t-light block p-2 w-full text-base focus:outline-none mt-2 border border-t-ccc bg-t-light"
            placeholder="Megjegyzés"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
        />
        </>
    )
}

export default BookingForm