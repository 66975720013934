import React from 'react'
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    const divClass = "flex flex-col items-center container my-4 lg:w-3/6 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10 rounded-lg"

    setTimeout(() => {
        navigate(`/`, { replace: true })
    }, 2500);

    return (
        <>
            <div className='admin-login'>
                <div className={`${divClass}`}>
                    <div className='h-12 w-12 mb-8'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                    </div>
                    <h1 className="text-center text-t-dark mb-8">
                        Ez az oldal nem található.
                    </h1>
                    <div className='text-lg'>Hamarosan visszairányítunk a kezdőlapra.</div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NotFound