import React, { useState, useEffect } from 'react'
import toMariaDBTimestamp from '../../helper/toMariaDBTimestamp';

const EventDatesBlock = ({ eventId, eventDate, updateEventDate, removeEventDate }) => {

    const [selectedDate, setSelectedDate] = useState(eventDate?.date || toMariaDBTimestamp(new Date()));
    const [startHour, setStartHour] = useState(
        eventDate?.start_hour !== undefined
        ? eventDate?.start_hour?.toString().padStart(2, '0')
        : '00'
    );
    
    const [startMinute, setStartMinute] = useState(
        eventDate?.start_minute !== undefined
        ? eventDate?.start_minute?.toString().padStart(2, '0')
        : '00'
    );

    useEffect(() => {
        updateEventDate(eventDate.uuid, { ...eventDate, date: selectedDate || toMariaDBTimestamp(new Date()), start_hour: startHour || 0, start_minute: startMinute || 0, event_id: eventId });
    }, [selectedDate, startHour, startMinute]);
    
  
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleBlurStartHour = () => {
        if (startHour?.length === 1) {
        setStartHour(`0${startHour}`);
        }
    };

    const handleBlurStartMinute = () => {
        if (startMinute?.length === 1) {
        setStartMinute(`0${startMinute}`);
        }
    };

    const handleStartHourChange = (e) => {
        const value = e.target.value;
        if (/^(2[0-3]|1[0-9]|[0-9])$/.test(value) || value === "") {
        setStartHour(value);
        }
    };

    const handleStartMinuteChange = (e) => {
        const value = e.target.value;
        if (/^[1-5]?[0-9]$/.test(value) || value === "") {
        setStartMinute(value);
        }
    };

    return (
        <div className='join w-full justify-center rounded-none items-end'>
            <div className='w-5/12'>
                <div className='my-2'>Milyen nap lesz?</div>
                <div className='rounded-none border border-t-ccc'>
                <input
                    className='focus:outline-none w-full h-10 px-2 bg-t-light'
                    type="date"
                    id="date-picker"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
                </div>
            </div>
            <div className='w-5/12'>
                <div className='my-2'>Mikor kezdődik?</div>
                <div className="join rounded-none relative flex items-center border border-t-ccc">
                    <input
                    type="text"
                    value={startHour}
                    onChange={handleStartHourChange}
                    onBlur={handleBlurStartHour}
                    placeholder="00"
                    className="text-right focus:outline-none w-full h-10 px-2 bg-t-light"
                    />
                    <span>:</span>
                    <input
                        type="text"
                        value={startMinute}
                        onChange={handleStartMinuteChange}
                        onBlur={handleBlurStartMinute}
                        placeholder="00"
                        className="text-left focus:outline-none w-full h-10 px-2 bg-t-light"
                    />
                </div>
            </div>
            <div className='w-1/12'>
                <div
                    className='h-9 ml-4 min-h-0 btn bg-t-pink btn-outline hover:bg-t-pink/75 text-t-dark align-middle text-5xl p-2 leading-11 font-normal'
                    onClick={() => removeEventDate(eventDate.uuid)}
                >
                    -
                </div>
            </div>
        </div>
    )
}

export default EventDatesBlock