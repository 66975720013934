import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function EventBlock({ identifier, event }) {
  
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [dateTypeName, setDateTypeName] = useState('');

  useEffect(() => {
    if(event.date_type === 'onetime') {
      setDateTypeName('Egyedi');
    } else if(event.date_type === 'recurring') {
      setDateTypeName('Ismétlődő');
    }
  }, []);

  const getRecurringDayLabel = (dayCode) => {
    const days = {
        H: "H",
        K: "K",
        Sze: "Sze",
        Cs: "Cs",
        P: "P",
        Szo: "Szo",
        V: "V",
    };
    return days[dayCode];
    };

  if(!event) {
    return (
      <div className='flex justify-center min-h-50-screen'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  return (
    <>
        <div className="card card-compact bg-base-100 shadow-6xl w-[calc(100vw-3rem)] sm:w-full">
          <figure>
              <div
                className="my-0 mx-auto w-full h-40 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${apiBaseUrl + event?.image})`,
                }}
              />
          </figure>

        <div className="card-body">
          <div className='flex justify-end'>
          {/* {event?.date_type && event?.date_type !== 'type' &&
            <div className='text-base text-t-darkp-2'>
              {event?.date_type === 'onetime' && 'Egyedi'}
              {event?.date_type === 'recurring' && 'Ismétlődő'}
            </div>
          } */}

          {event?.active ?
            <div className='text-base font-bold text-t-dark bg-t-green p-2'>Bekapcsolva</div>
          :
          <div className='text-base font-bold text-t-dark bg-t-pink p-2'>Kikapcsolva</div>
          }
          </div>

          <div className='flex flex-col'>
              <div className='text-xs font-bold'>Esemény neve:</div>
              {event?.label ?
                <div className='text-cut text-xl font-bold'>{event?.label}</div>
              :
                <div className='text-cut text-xl opacity-50'>Még nem adtad meg az esemény nevét</div>
              }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Leírás:</div>
            {event?.description ?
              <div className='text-cut-description text-base min-h-18' dangerouslySetInnerHTML={{ __html: event?.description }} />
            :
              <div className='opacity-50 text-base min-h-18'>Még nem adtad meg az esemény leírását</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Időtartam:</div>
            {(event?.hour && event?.minute) ?
              <div className='text-base'>{event?.hour} óra {event?.minute} perc</div>
            :
              <div className='opacity-50 text-base'>Még nem adtad meg az esemény időtartamát</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Maximális létszám:</div>
            {event.headcount ?
              <div className='text-base'>{event?.headcount}</div>
            :
              <div className='opacity-50 text-base'>Még nem adtad meg az esemény maximális létszámát</div>
            }
          </div>

          <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Ár:</div>
            {event.price ?
              <div className='text-base'>{event?.price} HUF</div>
            :
              <div className='opacity-50 text-base'>Még nem adtad meg az esemény árát</div>
            }
          </div>

          {/* <hr />
          <div className='flex flex-col'>
            <div className='text-xs font-bold'>Esemény típusa:</div>
            {(event.date_type && dateTypeName) ?
              <div className='text-base'>{dateTypeName}</div>
            :
              <div className='opacity-50 text-base'>Még nem választottál típust</div>
            }
          </div> */}

          {event?.date_type &&
          <>
            <hr />
            <div className='flex flex-col'>
              <div className='text-xs font-bold mb-2'>
                {(!event?.event_dates || event.event_dates.length <= 1) ? (
                  'Esemény időpontja:'
                ) : (
                  'Esemény időpontjai:'
                )}
              </div>
              {(event?.date_type === 'onetime') && (
                <>
                  {event?.event_dates.length > 0 ? (
                    <div className="text-base text-center">
                      {event.event_dates.sort((a, b) => new Date(a.date) - new Date(b.date)).map((dateItem, index) => (
                        <div className={`flex justify-between xs:justify-evenly sm:justify-between md:justify-evenly w-full py-2 px-2 xxs:px-4 mr-2 mb-2 ${event?.headcount > dateItem?.booking_count ? 'bg-t-blue' : 'bg-t-beige'} cursor-default`} key={index}>

                          <div className='flex w-4/12 justify-start'>
                            <svg className='hidden xs:block sm:hidden lg:block mr-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                            <div className='xxs:text-base sm:text-sm md:text-base text-sm'>{dateItem.date}</div>
                          </div>

                          <div className='border-r border-t-dark'></div>
                          
                          <div className='flex w-4/12 justify-center'>
                            <svg className='hidden xs:block sm:hidden lg:block mr-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                            <div className='text-center'>
                              {String(dateItem.start_hour).padStart(2, '0')}:
                              {String(dateItem.start_minute).padStart(2, '0')}
                            </div>
                          </div>

                          <div className='border-r border-t-dark'></div>

                          <div className='flex w-4/12 justify-center'>
                            <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            <div>{event?.headcount}/{dateItem?.booking_count}</div>
                          </div>

                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="opacity-50 text-base">Még nem adtad meg az esemény időpontját.</div>
                  )}
                </>
              )}
              {/* {(event?.date_type === 'recurring') &&
              <>
                <ul className="grid w-full gap-2 grid-cols-4 lg:grid-cols-7 mt-2 text-base">
                {Object.entries(JSON.parse(event.recurring_days)).map(([dayCode, checked]) => (
                  <li key={`${event.id}-${dayCode}`} className={`inline-flex items-center justify-center w-full p-2 text-t-dark ${checked ? 'bg-t-blue' : 'bg-t-gray opacity-50'} rounded-lg`}>
                        {getRecurringDayLabel(dayCode)}
                  </li>
                ))}
                </ul>
                {(event?.start_hour && event?.start_minute) &&
                <>
                  <div className='text-xs font-bold mt-4'>Esemény kezdete:</div>
                  <div className='mt-2 w-24 text-base inline-flex items-center justify-center p-2 text-t-dark border-2 bg-t-light rounded-lg'>{event?.start_hour}:{event?.start_minute}</div>
                </>
                }
              </>
              } */}
            </div>
          </>
          }

          {/* 
          <hr />
          <div className="mt-8">
            <button
              className="w-full btn bg-t-green btn-outline mt-4 mr-2 hover:bg-t-green/75"
              onClick={() => navigate(`/${identifier}/admin/${event?.id}/edit`, { replace: true })}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon></svg>
              Szerkesztés
            </button>
          </div>
          */}
        </div>
        <div className="card-actions pb-4 px-4">
            <button
                className="w-full btn bg-t-green btn-outline mt-4 mr-2 hover:bg-t-green/75"
                onClick={() => navigate(`/${identifier}/admin/${event?.id}/edit`, { replace: true })}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon></svg>
              Szerkesztés
            </button>
          </div>
        </div>
</>
  );
}
